import { Container } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

export const HomeTemplate = ({ page, renderSections, ...props }) => {
  const sections = renderSections();
  const visibleSections = sections?.filter(
    (section) => !section?.props?.hidden
  );
  const settings = useSettings();
  const { enableTransparentBackground: enable } = { ...settings?.header };
  return (
    <Container
      data-comp="HomeTemplate"
      sx={{
        mt: enable ? ['-53px', null, '-57px'] : 0, // needed for transparent background for nav
      }}
    >
      {visibleSections}
    </Container>
  );
};
